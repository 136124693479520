export const icons = {
  home: "fas fa-home",
  blog: "fas fa-keyboard",
  github: "fab fa-github",
  linkedin: "fab fa-linkedin",
  resume: "fas fa-file-alt",
  tasks: "fas fa-tasks",
  code: "fas fa-code-branch",
  education: "fas fa-user-graduate",
  basketball: "fas fa-basketball-ball",
  volunteer: "fas fa-hands-helping",
  terminal: "fas fa-terminal",
  sun: "fas fa-sun",
  moon: "fas fa-moon",
  trash: "far fa-trash-alt",
  book: "fas fa-book",
  none: "",
}